import React from "react";
import "./Loader.css";
const Copyright = () => {
  return (
    <div className="copyrightdiv">
      <p className="copyrighttext">
        {/* Copyright © 2024 <span> EKAKSHAR BUILDTECH PRIVATE LIMITED</span>. All */}
        {/* rights reserved. */}
      </p>
    </div>
  );
};

export default Copyright;
